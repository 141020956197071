import "../styles/globals.css";
import "../styles/fonts.css";
//import "tailwindcss/tailwind.css";
import "../components/App.css";
import "../components/FrontPageHeaderExtension.css";
import "../components/video-js.css";
import "../components/video-js-css-modifications.css";
//import "./libs/videojs-overlay/dist/videojs-overlay.css";
import "../components/css/videojs.markers.min.css";
import "../components/css/skin/default.css";

import "../components/VideoPlayer.css";

import { SessionProvider } from "next-auth/react";

import Fingerprint2 from "fingerprintjs2";
import React, { useEffect, useState } from "react";
import socketIO from "socket.io-client";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
let io = null;

var config = require("../config");
// analytics array
if (process.browser) {
  window.analyticsEvents = [];
  window.answers = [];
  window.answersSent = [];

  //if (!window.standaloneVideo) {
  io = socketIO.connect(
    config.socketIo /*, {
    "force new connection": true,
    reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
    timeout: 10000, //before connect_error and connect_timeout are emitted.
    
  } */
  );
  //}
}

var guid = function guid() {
  const options = {};
  return Fingerprint2.getPromise(options).then((components) => {
    const values = components.map((value) => value);
    return Fingerprint2.x64hash128(values.join(""), 31);
  });
};

(async function () {
  let fpid = null;
  if (process.browser) {
    fpid = await guid();
    window.fpid = fpid;
  }
  //console.log("FPID: " + fpid); // Now you can use it
  //document.cookie = `fpid=${encodeURIComponent(fpid)}`;
})();

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

if (typeof window !== "undefined") {
  // This ensures that as long as we are client-side, posthog is always ready
  // NOTE: If set as an environment variable be sure to prefix with `NEXT_PUBLIC_`
  // For more info see https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    session_recording: {
      // WARNING: Only enable this if you understand the security implications
      recordCrossOriginIframes: true,
    },
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [participantid, setParticipantid] = React.useState(null);
  const router = useRouter();
  /*
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []); */

  useEffect(() => {
    io.on("player", (payload) => {
      //console.log(payload);
      switch (payload.type) {
        case "LOAD_CONFIG_SUCCESS":
          setServerConfig(payload.data);
          break;

        case "NEW_VIDEO":
          console.log("new video project received from api");
          setVideo(payload.data);
          break;

        case "UPDATE_VIDEO":
          console.log("video project updated from api");
          setVideo(payload.data);
          break;

        case "UPDATE_SESSION":
          console.log("new session updated from api");

          if (payload.data.errormessage) setErrorMessage(payload.data.errormessage);
          if (payload.data.link) setLink(payload.data.link);
          if (payload.data.session) setSession(payload.data.session);
          if (payload.data.session && payload.data.session.sessionid) setSessionid(payload.data.session.sessionid);
          if (payload.data.video && payload.data.video.projectid) setProjectid(payload.data.video.projectid);
          if (payload.data.video) {
            let tempvideo = payload.data.video;

            setVideo(tempvideo);
          }

          break;

        case "ANALYTICS_ADD_TO_QUEUE_SUCCESS":
          for (let a of window.analyticsEvents) {
            if (payload.data.analyticsid === a.analyticsid) {
              a.delivered = true;
              //console.log("analytics delivered");
            }
          }
          break;

        case "UPDATE_ANSWERS_FROM_SERVER":
          setUpdateAnswersFromServer(payload);
          break;

        case "DOWNLOAD_CAPTION_SUCCESS":
          //window.open(payload.data.base64);

          var byteArray = Base64Binary.decodeArrayBuffer(payload.data.base64);
          var file = new Blob([byteArray], { type: "text/plain" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          break;

        case "CREATE_ANSWERS_PDF_SUCCESS":
          //window.open(payload.data.base64);

          var byteArray = Base64Binary.decodeArrayBuffer(payload.data.base64);
          var file = new Blob([byteArray], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          break;
        default:
          break;
      }
    });
  }, []); //only re-run the effect if new message comes in

  // send analytics events every second
  useInterval(() => {
    for (let a of window.analyticsEvents) {
      let aevent = JSON.parse(JSON.stringify(a));
      aevent.participantid = participantid;
      if (!aevent.delivered)
        io.emit("player", Object.assign({ type: "ANALYTICS_ADD_TO_QUEUE", playerkey: config.playerkey, data: aevent }));
    }
  }, 1000);

  return (
    <PostHogProvider client={posthog}>
      <SessionProvider session={session}>
        <Component {...pageProps} />
      </SessionProvider>
    </PostHogProvider>
  );
}

export default MyApp;
