module.exports = {
  mainPage: "",
  player: "https://automate.video",
  socketIo: "https://api1.automate.video",
  appAddress: "https://app.automate.video",
  oldPlayer: "https://old.automate.video",
  mediacache: "https://mediacache.automate.video",
  playerkey: "SDFJIWR3sssdgwwerweWLK23423424!sldkfj9s49g499g4cccf",
  debug: false,
};
